import { BounceOutMode } from "./BounceOutMode";
import { DestroyOutMode } from "./DestroyOutMode";
import { NoneOutMode } from "./NoneOutMode";
import { OutOutMode } from "./OutOutMode";
export class OutOfCanvasUpdater {
    constructor(container) {
        this.container = container;
        this._updateOutMode = (particle, delta, outMode, direction) => {
            for (const updater of this.updaters) {
                updater.update(particle, direction, delta, outMode);
            }
        };
        this.updaters = [
            new BounceOutMode(container),
            new DestroyOutMode(container),
            new OutOutMode(container),
            new NoneOutMode(container),
        ];
    }
    init() {
    }
    isEnabled(particle) {
        return !particle.destroyed && !particle.spawning;
    }
    update(particle, delta) {
        const outModes = particle.options.move.outModes;
        this._updateOutMode(particle, delta, outModes.bottom ?? outModes.default, "bottom");
        this._updateOutMode(particle, delta, outModes.left ?? outModes.default, "left");
        this._updateOutMode(particle, delta, outModes.right ?? outModes.default, "right");
        this._updateOutMode(particle, delta, outModes.top ?? outModes.default, "top");
    }
}
